/*
 * project.less
 * -----------------------------------------------
*/

.project .thumb {
  position: relative;
  overflow: hidden;
}
.project:hover .thumb::after {
  border: 10px solid rgba(255, 255, 255, 0.2);
  opacity: 1;
}
.project .thumb::after {
  background: rgba(0, 0, 0, 0.6) none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition-duration: 0.3s;
  width: 100%;
}
.project .hover-link {
  left: 44%;
  opacity: 0;
  position: absolute;
  top: 20%;
  transition-duration: 0.3s;
  z-index: 9;
}
.project:hover .hover-link {
  opacity: 1;
  top: 44%;
}
.project .hover-link i {
  color: #fff;
  display: block;
  font-size: 20px;
  padding: 10px;
}
.project .project-details {
  background: #fff none repeat scroll 0 0;
  border-bottom: 3px solid #FFBD1F;
  transition-duration: 0.3s;
}
.project:hover .project-details {
  border-bottom: 3px solid #fff;
}