@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:        industrypress
Version:        2.0
Last change:    02.20.2016
Primary use:    Attorney & Lawyer Business Template
Author:         ThemeMascot
URL:            http://themeforest.net/user/ThemeMascot
-------------------------------------------------------------------*/

// Import bootstrap variables and mixins for reference
@import (reference) "less-bootstrap/variables.less";
@import (reference) "less-bootstrap/mixins.less";

//fonts
@import "../css/font-awesome.min.css";
@import "../css/font-awesome-animation.min.css";
@import "../css/pe-icon-7-stroke.css";
/* @import "../css/elegant-icons.css"; */
/* @import "../css/stroke-gap-icons.css"; */
@import "../fonts/icomoon/style.css";
@import "../css/utility-classes.css";
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800|Raleway:400,300,200,500,700,600,800|Trirong:100,200,300,400,500,600,700,800,900);

 
// Table of contents
@import "less-industrypress/table-of-content.less";

 
// Initialize Variables
@import "less-industrypress/variables.less";
@import "less-industrypress/mixins.less";
// Typography
@import "less-industrypress/typography.less";

// Common Styles
@import "less-industrypress/common.less";
@import "less-industrypress/extra.less";
@import "less-industrypress/overlay.less";

// Header
@import "less-industrypress/header.less";

// Nav
@import "less-industrypress/nav.less";

// Content Blocks
@import "less-industrypress/topbar.less";
@import "less-industrypress/inner-header-title.less";
@import "less-industrypress/vertical-nav.less";
@import "less-industrypress/menu-full-page.less";
@import "less-industrypress/boxed-layout.less";
@import "less-industrypress/form.less";
@import "less-industrypress/side-push-panel.less";
@import "less-industrypress/box-hover-effect.less";
@import "less-industrypress/gallery-isotope.less";
@import "less-industrypress/sliders.less";
@import "less-industrypress/home.less";
@import "less-industrypress/about.less";
@import "less-industrypress/project.less";
@import "less-industrypress/volunteer.less";
@import "less-industrypress/contact.less";
@import "less-industrypress/event.less";
@import "less-industrypress/practice-area.less";
@import "less-industrypress/services.less";
@import "less-industrypress/job.less";
@import "less-industrypress/shop.less";
@import "less-industrypress/blog.less";

// Shortcodes
@import "less-industrypress/shortcodes.less";


// Widgets
@import "less-industrypress/widgets.less";


// Footer
@import "less-industrypress/footer.less";